var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("span", [_vm._v(_vm._s(_vm.displayedItems.join(", ")))]),
      _vm.hasHiddenItems
        ? _c(
            "v-btn",
            {
              staticClass: "ml-2",
              attrs: { outlined: "", text: "", small: "" },
              on: { click: _vm.toggleShowAll },
            },
            [_vm._v(" " + _vm._s(_vm.toggleLabel) + " ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
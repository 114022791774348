<template>
  <v-dialog v-model="dialog" content-class="criteria-dialog">
    <v-card :loading="loading" height="700" class="card-outer">
      <v-card-text v-if="!loading" class="card-body">
        <v-row>
          <v-col cols="12" md="3">
            <v-list shaped>
              <v-list-item-group v-model="builderType" color="primary">
                <v-list-item value="check" @click="builderType = 'check'">
                  <v-list-item-content>
                    <v-list-item-title>Check Field</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item value="comparison" @click="builderType = 'comparison'">
                  <v-list-item-content>
                    <v-list-item-title>Make Comparison</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>

          <v-col cols="12" md="9" height="700">
            <v-row dense v-if="builderType === 'check'">
              <div class="field-list overflow">
                <template v-for="(group, i) in criteria">
                  <v-row :key="i" v-if="group.fields.length" dense>
                    <v-col cols="12" md="12" dense>
                      <h5>{{ group.heading }}</h5>
                    </v-col>

                    <div class="checkbox-wrapper">
                      <v-checkbox
                        :key="i"
                        :label="field.label"
                        :value="field.selected"
                        class="checkbox"
                        dense
                        v-for="(field, i) in group.fields"
                        v-model="field.selected"
                      ></v-checkbox>
                    </div>
                  </v-row>
                </template>
              </div>
            </v-row>

            <v-form v-model="valid" v-if="builderType === 'comparison'" class="comparison-container overflow">
              <template v-for="(comparison, index) in comparisonCriteria">
                <v-row :key="index" v-if="!comparison.deleted">
                  <v-col>
                    <v-select
                      :item-text="getItemText"
                      :items="comparisonTypeOptions"
                      @change="checkComparisonType(index)"
                      label="Field"
                      outlined
                      return-object
                      v-model="comparison.type"
                      :rules="[rules.required]"
                    ></v-select>
                  </v-col>

                  <v-col>
                    <v-select
                      :items="comparisonOperators"
                      label="Comparator"
                      outlined
                      v-model="comparison.operator"
                      :rules="[rules.required]"
                    ></v-select>
                  </v-col>

                  <v-col>
                    <date-picker label="Date" v-if="comparison.showDatePicker" v-model="comparison.value"></date-picker>

                    <v-text-field
                      v-else
                      v-model.trim="comparison.value"
                      type="number"
                      label="Value"
                      outlined
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="2">
                    <v-btn @click="deleteComparison(index)" color="error" fab v-if="comparisonCriteria.length"
                      ><v-icon>mdi-delete</v-icon></v-btn
                    >
                  </v-col>
                </v-row>
              </template>

              <v-col cols="12">
                <v-row justify="center">
                  <v-btn
                    @click="() => comparisonCriteria.push({ label: '', value: '', operator: '', deleted: false })"
                    color="primary"
                    fab
                    text
                    :disabled="!canAddNewComparison"
                    ><v-icon>mdi-plus</v-icon></v-btn
                  >
                </v-row>
              </v-col>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>

      <div class="card-footer">
        <div class="action-buttons">
          <v-btn class="mr-2" dark color="error" @click="cancel()"> Cancel </v-btn>
          <v-btn :disabled="!canSave" color="success" @click="commit()"> OK </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { GET_CONFIG } from '@/store/modules/Config/actions';
import { getCriteriaFields, getComparisonFields } from '@/util/getCriteria';

import { required } from '@/util/rules';

import DatePicker from '@/components/DatePicker.vue';

const FIELD_LABEL_MAP = new Map();
const FUNDING_SOURCE_LABEL = 'Funding Source';

export default {
  name: 'CriteriaBuilder',
  inject: ['eventHub'],
  components: { DatePicker },
  props: {
    tripTypes: Array,
    value: { type: Array, default: () => [] },
  },
  data() {
    return {
      getComparisonFields,
      getCriteriaFields,
      builderType: 'check',
      checkedCriteria: [],
      comparisonCriteria: [],
      comparisonOperators: ['<', '>', '='],
      comparisonTypeOptions: [],
      criteria: [],
      customFormFields: [],
      dialog: false,
      loading: true,
      valid: false,
      rules: {
        required,
      },
    };
  },
  computed: {
    ...mapGetters('tripEvent', ['tripEvents']),
    ...mapGetters('vehicleType', ['vehicleTypes']),
    canSave() {
      if (this.loading) return false;
      if (!this.comparisonCriteria.length) return true;

      const activeCriteria = this.comparisonCriteria.filter((criteria) => !criteria.deleted);
      const isCriteriaValid = activeCriteria.every((active) => active?.type?.label && active.operator && active.value);
      if (isCriteriaValid) return true;

      return false;
    },
    canAddNewComparison() {
      if (this.builderType !== 'comparison') return false;
      if (!this.comparisonCriteria.length) return true;
      if (this.valid && this.comparisonCriteria.length) return true;

      return false;
    },
  },
  methods: {
    ...mapActions('config', [GET_CONFIG]),
    ...mapActions('vehicleType', ['getVehicleTypes']),
    isFundingSourceChecked(list) {
      return list.some((group) =>
        group.fields.some((criteria) => criteria.label === FUNDING_SOURCE_LABEL && criteria.selected === true)
      );
    },
    isOtherCriteriaChecked(list) {
      return list.some((group) =>
        group.fields.some((criteria) => criteria.label !== FUNDING_SOURCE_LABEL && criteria.selected === true)
      );
    },
    async fetchItems() {
      this.loading = true;

      const trc = await this.getConfig('tripRequestForm');
      const cff = await this.getConfig('customFormFields');

      let customFields = [];

      Object.keys(cff).forEach((e) => {
        customFields = customFields.concat(cff[e]);
      });

      this.criteria = this.getCriteriaFields(
        { labels: trc.labels, display: trc.display, tripTypes: this.tripTypes },
        this.tripEvents,
        this.vehicleTypes,
        this.customFormFields
      );

      this.comparisonTypeOptions = this.getComparisonFields(this.customFormFields);
      this.customFormFields = customFields;
      this.comparisonCriteria = [];

      __setFieldLabelMap(this.criteria);

      this.loading = false;

      this.handleValueChange(this.value);
    },
    cancel() {
      this.comparisonCriteria = [];
      this.comparisonTypeOptions = [];

      this.dialog = false;
    },
    deleteComparison(comparisonIndex) {
      this.comparisonCriteria = this.comparisonCriteria.map((criteria, index) => {
        if (index === comparisonIndex) criteria.deleted = true;
        return criteria;
      });
    },
    getItemText(item) {
      return item.custom ? item.prependLabel + item.label : item.label;
    },
    checkComparisonType(comparisonIndex) {
      this.comparisonCriteria = this.comparisonCriteria.map((criteria, index) => {
        if (index === comparisonIndex) {
          criteria.showDatePicker =
            criteria.type && (criteria.type.check === 'leaveDate' || criteria.type.check === 'returnDate');
        }

        return criteria;
      });
    },
    handleValueChange(newValues) {
      if (!newValues || !newValues.length || this.loading) return;

      // Prevents duplicate criteria records
      const fieldLabelSet = new Set();

      for (let index = 0; index < newValues.length; index++) {
        const value = newValues[index];
        const label = `${value.label || ''}`.trim();

        let field = {};

        if (FIELD_LABEL_MAP.has(label)) {
          // Extends checkbox parent values for this component
          if (fieldLabelSet.has(label)) continue;

          field = FIELD_LABEL_MAP.get(label);

          field.selected = !__checkDeleted(value);
          field.check = value.check;
          field.id = value.id;
          field.label = value.label;

          fieldLabelSet.add(label);
        } else {
          // Converts comparison parent values to form readable entry
          __parseComparisonFunction(value, this.comparisonTypeOptions);

          this.comparisonCriteria.push({ ...value });
        }
      }
    },
    commit() {
      const isFundingSource = this.isFundingSourceChecked(this.criteria);
      const isOtherCriteria = this.isOtherCriteriaChecked(this.criteria);

      if (isFundingSource && isOtherCriteria) {
        this.$myalert.error('Both funding source and other criteria cannot be selected simultaneously.', true);
        return;
      }

      const fields = [];

      this.criteria.map((c) => fields.push(...c.fields));

      const fieldsToSave = fields.filter((f) => {
        if (f.id && !f.selected) {
          f.deleted = true;

          return true;
        }

        return f.selected;
      });

      const comparisonFieldsToSave = [];

      this.comparisonCriteria.map((c) => {
        const record = __convertComparisonToCriteria(c);

        if (!c.id && c.deleted) return;

        // If record needs updating, delete and recreate the record - no update available
        if (c.id) comparisonFieldsToSave.push({ ...record, deleted: true });

        comparisonFieldsToSave.push({ ...record, id: undefined });
      });

      const criteria = [...fieldsToSave, ...comparisonFieldsToSave];

      this.$emit('input', criteria);
      this.cancel();
    },
  },
  watch: {
    value(newValues) {
      this.handleValueChange(newValues);
    },
  },
  async mounted() {
    try {
      this.loading = true;
      await this.getVehicleTypes({ setupTable: true });
    } catch (e) {
      this.$myalert.error('A problem occurred while fetching data.', true);
    } finally {
      this.loading = false;
    }
  },
};

/**
 * Check Deleted
 * @param {Criteria}
 * @return {boolean}
 */
const __checkDeleted = (field) => Object.prototype.hasOwnProperty.call(field, 'deleted') && field.deleted;

/**
 * Convert comparison to criteria
 * @param {Partial<Criteria>}
 * @return {Criteria}
 */
const __convertComparisonToCriteria = ({ approvalLevelId, id, type, operator, value, deleted = false }) => {
  return {
    approvalLevelId,
    check: `${type.check}`,
    compField: type.check,
    compOp: operator,
    compValue: value,
    custom: true,
    deleted,
    func: `${type.check} ${operator} ${value}`,
    id,
    label: `${type.prependLabel ? type.prependLabel : ''}${type.label} ${operator} ${value}`,
  };
};

/**
 * Parse comparison function
 * @description Takes incoming values from parent, and converts them to "form" readable variations
 */
const __parseComparisonFunction = (field, comparisonTypeOptions) => {
  const { func, label } = field;

  let splitOperator = '';

  if (label.includes('===')) {
    splitOperator = '===';
    field.operator = '=';
  } else if (label.includes('=')) {
    splitOperator = '=';
    field.operator = '=';
  } else if (label.includes('<')) {
    splitOperator = '<';
    field.operator = '<';
  } else if (label.includes('>')) {
    splitOperator = '>';
    field.operator = '>';
  }

  const splitString = label.split(` ${splitOperator} `);

  if (!field.type) {
    // Find and assign type of comparison criteria
    for (const compOption of comparisonTypeOptions) {
      if (!func.includes(compOption.check)) continue;

      field.type = { ...compOption };
      break;
    }
  }

  field.value = splitString[1]?.trim();
};

/**
 * Set field label map
 * @description Sets up a map to help prevent duplicate entries
 */
const __setFieldLabelMap = (criteriaPresets) => {
  FIELD_LABEL_MAP.clear();

  for (let index = 0; index < criteriaPresets.length; index++) {
    const criteria = criteriaPresets[index];

    if (!criteria || !criteria.fields || !criteria.fields.length) continue;

    const fields = criteria.fields;

    for (let index = 0; index < fields.length; index++) {
      const field = fields[index];

      FIELD_LABEL_MAP.set(`${field.label}`.trim(), field);
    }
  }
};
</script>

<style scoped>
.criteria-container {
  margin: 0 !important;
}

.field-list {
  padding-top: 20px;
}

.overflow {
  overflow-y: auto;
  overflow-x: hidden;
  height: 620px;
}

.checkbox-wrapper {
  display: flex;
  align-items: center !important;
  flex-wrap: wrap;
  padding: 12px;
}

.checkbox {
  margin-right: 20px;
}

.comparison-container {
  padding-top: 20px !important;
}

.card-outer {
  padding-bottom: 50px;
  padding-top: 12px;
}

.card-body {
  min-height: 100%;
}

.card-footer {
  display: flex;
  flex-direction: row-reverse;
  padding: 0 24px 20px;
}
</style>

<template>
  <div>
    <span>{{ displayedItems.join(', ') }}</span>
    <v-btn outlined v-if="hasHiddenItems" class="ml-2" text small @click="toggleShowAll">
      {{ toggleLabel }}
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    items: { type: Array, required: true },
    maxItems: { type: Number, default: 3 },
  },
  data() {
    return {
      showAll: false,
    };
  },
  computed: {
    displayedItems() {
      return this.showAll ? this.items : this.items.slice(0, this.maxItems);
    },
    hiddenItemsCount() {
      return this.items.length - this.maxItems;
    },
    hasHiddenItems() {
      return this.items.length > this.maxItems;
    },
    toggleLabel() {
      return this.showAll ? 'Show Less' : `+${this.hiddenItemsCount} more`;
    },
  },
  methods: {
    toggleShowAll() {
      this.showAll = !this.showAll;
    },
  },
};
</script>
